<!--
 * @Description: 登录注册
 * @Author: zhang zhen
 * @Date: 2023-02-05 14:48:41
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-05-19 19:01:21
 * @FilePath: /page-sass/src/views/login.vue
-->

<template>
  <div class="login-content">
    <div class="login-header">
      <img src="~@/assets/mainLogo.png" alt="" class="logo">
    </div>
    <div class="login-content-right">
      <div class="login-box">
        <img src="~@/assets/login/logo_color.png" alt="" class="center-logo">
        <template v-if="type == 'login'">
          <a-tabs v-model="activeKey" @change="handleSwitchTab" class="loginTab">
            <a-tab-pane key="1" tab="快捷登录">
              <div class="login-box-content">
                <a-form-model ref="loginByPhone" :model="form" :rules="loginPhoneRule"
                  @keyup.enter.native="handleLoginByPhone">
                  <a-form-model-item prop="mobile" class="mb-16">
                    <a-input class="need-desc" placeholder="请输入手机号" v-model="form.mobile">
                      <template slot="prefix">
                        <span class="desc">中国+86</span>
                      </template>
                    </a-input>
                  </a-form-model-item>

                  <a-form-model-item prop="captcha" class="mb-16">
                    <a-input-group compact>
                      <div class="inp-box" style="margin-top: 0">
                        <a-input placeholder="请输入短信验证码" v-model="form.captcha"></a-input>
                        <div class="block-line"></div>
                        <a-button class="inp-txt" type="link" :loading="sendCodeSec > 0" @click="handleLoginSendMsg">{{
          sendCodeSec == 0 ? '获取验证码' : `${sendCodeSec}s`
        }}</a-button>
                      </div>
                    </a-input-group>
                  </a-form-model-item>
                  <a-button type="primary" htmlType="submit" block class="login-btn mb-15"
                    @keydown.enter="handleLoginByPhone" @click="handleLoginByPhone" :loading="loading">登录</a-button>
                  <p class="inp-right-txt"><span style="cursor: pointer;"
                      @click="handleChangeTab('register')">免费注册</span>
                  </p>
                  <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
                    <div class="police">
                      登录即表示已阅读并同意<a href="/userAgreement.html" target="_blank" class="link">《用户协议》</a> 和 <a
                        href="/userAgreement.html" target="_blank" class="link">《隐私政策》</a>
                    </div>
                  </a-form-model-item>
                </a-form-model>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="密码登录" class="right-tab">
              <div class="login-box-content">
                <a-form-model ref="loginByUsername" :model="form" :rules="loginByUsernameRule"
                  @keyup.enter.native="handleLoginByUsername">
                  <a-form-model-item prop="username" class="mb-16">
                    <a-input placeholder="请输入账号" v-model="form.username" />
                  </a-form-model-item>
                  <a-form-model-item prop="password" class="mb-16">
                    <a-input-password placeholder="请输入密码" v-model="form.password" :maxLength="20" />
                  </a-form-model-item>

                  <a-button class="login-btn mb-15" htmlType="submit" type="primary" block
                    @keydown.enter="handleLoginByUsername" @click="handleLoginByUsername"
                    :loading="loading">登录</a-button>
                  <div class="inp-right-txt">
                    <span @click="handleChangeTab('register')">免费注册</span>
                    <span style="padding: 0 10px"></span>
                    <span @click="handleChangeTab('forgetPassword')" style="color: #999;">忘记密码</span>
                  </div>
                  <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
                    <div class="police">
                      登录即表示已阅读并同意<a href="/userAgreement.html" target="_blank" class="link">《用户协议》</a> 和 <a
                        href="/userAgreement.html" target="_blank" class="link">《隐私政策》</a>
                    </div>
                  </a-form-model-item>
                </a-form-model>
              </div>
            </a-tab-pane>
          </a-tabs>
          <!-- 登录区域 -->

        </template>

        <template v-else-if="type == 'register'">
          <div class="memo-header">
            <span class="title">用户注册</span>
            <div class="loginBtn">
              已有账号，<a-button type="link" @click="handleChangeTab('login')" style="color: #ff6e2d">立即登录</a-button>
            </div>
          </div>
          <a-tabs v-model="activeKey" @change="handleSwitchTab" class="register-tabs">
            <a-tab-pane key="1" tab="短信注册">
              <div class="login-box-content">
                <a-form-model ref="registerPhone" :model="form" :rules="registerPhoneRule" :label-col="{ span: 7 }"
                  :wrapper-col="{ span: 17 }" labelAlign="left" @keyup.enter.native="handleRegisterByPhone">
                  <a-form-model-item prop="username" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }" class="mb-16">
                    <a-input placeholder="请输入账号" v-model="form.username" />
                  </a-form-model-item>
                  <a-form-model-item prop="mobile" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }" class="mb-16">
                    <a-input class="need-desc" placeholder="请输入手机号" v-model="form.mobile" allowClear>
                      <template slot="prefix">
                        <span class="desc">中国+86</span>
                      </template>
                    </a-input>
                  </a-form-model-item>
                  <a-form-model-item prop="captcha" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }" class="mb-16">
                    <a-input-group compact>
                      <div class="inp-box" style="margin-top: 0">
                        <a-input placeholder="请输入短信验证码" v-model="form.captcha"></a-input>
                        <div class="block-line"></div>
                        <a-button type="link" class="inp-txt" :loading="sendCodeSec > 0" @click="handleShowCaptcha">{{
          sendCodeSec == 0 ? '获取验证码' : `${sendCodeSec}s`
        }}</a-button>
                      </div>
                    </a-input-group>
                  </a-form-model-item>
                  <a-form-model-item prop="newPassword" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }"
                    class="mb-16">
                    <a-input-password placeholder="密码为8-16位字母+数字" v-model="form.newPassword" :maxLength="20" />
                  </a-form-model-item>
                  <a-form-model-item prop="pwd" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }" class="mb-16">
                    <a-input-password placeholder="再次输入密码" v-model="form.pwd" :maxLength="20" />
                  </a-form-model-item>
                  <a-form-model-item prop="roleType" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }" class="mb-16">
                    <div class="need-desc compact-input">
                      <span class="desc">用户类型</span>
                      <a-select v-model="form.roleType" placeholder="请选择用户类型" @change="handleSwitchUserType">
                        <a-icon type="caret-down" slot="suffixIcon" style="color: #8c8c8c" />
                        <a-select-option :value="undefined">请选择用户类型</a-select-option>
                        <a-select-option v-for="(item, key) in userTypList" :key="item.label" :value="item.value">
                          {{ item.label }}
                        </a-select-option>
                      </a-select>
                    </div>
                    <!-- <a-radio-group v-model="form.roleType" :options="userTypList" @change="handleSwitchUserType" /> -->
                  </a-form-model-item>
                  <a-form-model-item prop="tradeIdentity" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }"
                    class="mb-16">
                    <div class="need-desc compact-input">
                      <span class="desc">用户角色</span>
                      <a-select v-model="form.tradeIdentity" @change="handleChangeIdentity" placeholder="请选择用户角色">
                        <a-icon type="caret-down" slot="suffixIcon" style="color: #8c8c8c" />
                        <a-select-option :value="undefined">请选择用户角色</a-select-option>
                        <a-select-option v-for="(item, key) in tradeIdentityList[form.roleType]" :key="item.label"
                          :value="item.value">
                          <a-tooltip placement="bottomLeft">
                          <template slot="title">
                            <span>{{ item.desc }}</span>
                          </template>
                          <span class="inline-text">{{ item.label }}</span>
                        </a-tooltip>
                          
                        </a-select-option>
                      </a-select>
                    </div>
                  </a-form-model-item>
                  <a-button type="primary" class="login-btn" block style="margin: 0 0 15px"
                    @click="handleRegisterByPhone" :loading="loading">注册</a-button>
                  <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }" style="margin-top: 8px;">
                    <div class="police">
                      <a-checkbox v-model="form.isRead"></a-checkbox> 我已阅读并同意<a href="/userAgreement.html"
                        target="_blank" class="link">《用户协议》</a> 和 <a href="/userAgreement.html" target="_blank"
                        class="link">《隐私政策》</a>
                    </div>
                  </a-form-model-item>
                </a-form-model>
              </div>
            </a-tab-pane>
          </a-tabs>
        </template>

        <template v-else>
          <div class="memo-header" style="margin-bottom: 10px">
            <span class="title">重置密码</span>
            <div class="loginBtn">
              已有账号，<a-button type="link" @click="handleChangeTab('login')" style="color: #ff6e2d">立即登录</a-button>
            </div>
          </div>
          <a-tabs v-model="activeKey" class="resetPassword" @change="handleSwitchTab">
            <a-tab-pane key="1" tab="短信重置密码">
              <div class="login-box-content" style="padding-top: 0">
                <a-form-model ref="restByPhone" :model="form" :rules="resetPhoneRule">
                  <a-form-model-item prop="phone" class="mb-16">
                    <a-input class="need-desc" placeholder="请输入手机号" v-model="form.phone" allowClear>
                      <template slot="prefix">
                        <span class="desc">中国+86</span>
                      </template>
                    </a-input>
                  </a-form-model-item>
                  <a-form-model-item prop="captcha" class="mb-16">
                    <a-input-group compact>
                      <div class="inp-box">
                        <a-input placeholder="请输入短信验证码" v-model="form.captcha"></a-input>
                        <div class="block-line"></div>
                        <p class="inp-txt" :loading="sendCodeSec > 0" @click="handleSendRestMessage">
                          {{ sendCodeSec == 0 ? '获取验证码' : `${sendCodeSec}s` }}
                        </p>
                      </div>
                    </a-input-group>
                  </a-form-model-item>
                  <a-form-model-item prop="password" class="mb-16">
                    <a-input-password placeholder="密码为8-16位字母+数字" v-model="form.password" />
                  </a-form-model-item>
                  <a-button type="primary" class="login-btn" style="margin: 11px 0 15px" block
                    @click="handleResetPasswordByPhone" :loading="loading">登录</a-button>
                  <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }" style="margin-top: 8px;">
                    <div class="police">
                      登录即表示已阅读并同意<a href="/userAgreement.html" target="_blank" class="link">《用户协议》</a> 和 <a
                        href="/userAgreement.html" target="_blank" class="link">《隐私政策》</a>
                    </div>
                  </a-form-model-item>
                </a-form-model>

              </div>
            </a-tab-pane>
          </a-tabs>
        </template>
      </div>
    </div>
    <!-- 验证码 -->
    <Captcha ref="Captcha" @ok="handleSendMessage" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { timeFix } from '@/util/utils'
import { postAction, getAction, postQueryAction } from '@/api/manage'
import Captcha from '@/components/tools/captcha.vue'
export default {
  name: 'login',
  components: {
    Captcha,
  },
  data() {
    let validatePass = (rule, value, callback) => {
      let reg = /^(?=.*[a-zA-Z])(?=.*\d).+$/
      if (!value) {
        callback(new Error('请输入密码'))
        // 密码（8-20位）
      } else if (value.length < 8 || value.length > 16) {
        callback(new Error('密码长度要求密码长度应至少为 8 个字符，最多不超过 16 个字符'))
      } else if (!reg.test(value)) {
        callback(new Error('密码必须包含字母加数字'))
      } else {
        if (this.form.password !== '') {
          this.$refs.registerPassword.validateField('password')
        }
        callback()
      }
    }
    let validatePassPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('再次输入密码'))
      } else if (value !== this.form.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    let validatePassPhone1 = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入密码'))
        // 密码（8-20位）
      } else if (value.length < 8 || value.length > 20) {
        callback(new Error('请输入8-20位密码'))
      } else {
        if (this.form.password !== '') {
          this.$refs.registerPhone.validateField('password')
        }
        callback()
      }
    }
    let validatePass2 = (rule, value, callback) => {
      if (!value) {
        callback(new Error('再次输入密码'))
      } else if (value !== this.form.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    const validatePhone = (rule, value, callback) => {
      let reg = new RegExp(/^(?:(?:\+|00)86)?1[3-9]\d{9}$/)
      if (!value) {
        callback(new Error('请输入手机号'))
      } else if (!reg.test(value)) {
        callback(new Error('请输入有效的手机号'))
      } else {
        callback()
      }
    }

    const validateEmail = (rule, value, callback) => {
      let reg = new RegExp(/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/)
      if (!value) {
        callback(new Error('请输入邮箱'))
      } else if (!reg.test(value)) {
        callback(new Error('请输入正确的邮箱'))
      } else {
        callback()
      }
    }
    const validateIdentity = (rule, value, callback) => {
      if (value == null) {
        callback(new Error('请选择用户角色'))
      } else {
        callback()
      }
    }
    return {
      type: 'login',
      form: {},
      sendCodeSec: 0,
      activeKey: '1',
      timer: null,
      registerPasswordRule: {
        username: { required: true, message: '账号不能为空' },
        newPassword: { required: true, validator: validatePass },
        password: { required: true, validator: validatePass2 },
        tradeIdentity: { required: true, message: '请选择用户角色', validator: validateIdentity },
        roleType: { required: true, message: '请选择用户类型' },
        isRead: { required: true, message: '请勾选隐私协议' },
      },
      registerPhoneRule: {
        username: [
          { required: true, message: '请输入账号' },
          { required: true, max: 20, message: '账号最多不超过 20 个字符' },
        ],
        mobile: { required: true, validator: validatePhone },
        captcha: { required: true, message: '请输入短信验证码' },
        newPassword: { required: true, validator: validatePassPhone1, trigger: 'change' },
        pwd: { required: true, validator: validatePassPhone, trigger: 'change' },
        tradeIdentity: { required: true, message: '请选择用户角色', validator: validateIdentity },
        roleType: { required: true, message: '请选择用户类型' },
        isRead: { required: true, message: '请勾选隐私协议' },
      },
      loginPhoneRule: {
        mobile: { required: true, validator: validatePhone },
        captcha: { required: true, message: '请输入短信验证码' },
      },
      loginByUsernameRule: {
        username: { required: true, message: '账号不能为空' },
        password: [
          { required: true, message: '请输入登录密码' },
          { required: true, min: 8, max: 16, message: '密码长度要求密码长度应至少为 8 个字符，最多不超过 16 个字符' },
        ],
      },
      loading: false,
      resetPhoneRule: {
        phone: { required: true, validator: validatePhone, trigger: 'change' },
        captcha: { required: true, message: '请输入短信验证码' },
        password: [
          { required: true, message: '请输入登录密码' },
          { required: true, min: 8, max: 20, message: '请输入8-20位密码' },
        ],
      },
      resetEmailRule: {
        email: { required: true, validator: validateEmail },
        captcha: { required: true, message: '请输入短信验证码' },
        password: [
          { required: true, message: '请输入登录密码' },
          { required: true, min: 8, max: 20, message: '请输入8-20位密码' },
        ],
      },
      userTypList: [
        {
          label: '企业',
          value: 'BUSINESS',
        },
        {
          label: '个人',
          value: 'PERSON',
        },
      ],
      // 0-供应商，1-服务商，2-采购商
      tradeIdentityList: {
        BUSINESS: [
          {
            label: '采购商',
            value: 2,
            desc: '发布采购需求的用户角色'
          },
          {
            label: '供应商/贸易商',
            value: 0,
            desc: '发布需求、接收订单、生产订单的制造、贸易或服务用户角色'

          },
        ],
        PERSON: [
          {
            label: '采购商',
            value: 2,
            desc: '发布采购需求的用户角色'

          },
        ],
      },
    }
  },
  created() {
    let routerType = this.$route.query.type
    this.type = routerType || 'login'
  },
  methods: {
    ...mapActions(['login']),
    handleChangeIdentity() {
      this.$forceUpdate()
    },
    handleSwitchUserType() {
      // this.tradeIdentityList[1]['disabled'] = this.form.roleType && this.form.roleType == 'PERSON'
      this.form.tradeIdentity = undefined
      this.$forceUpdate()
    },
    handleLoginSendMsg() {
      this.$refs.loginByPhone.validateField('mobile', (res) => {
        if (!res) {
          this.$refs.Captcha.handleOpenModal(this.form.mobile)
        }
      })
    },
    // 重置密码
    handleSendRestMessage() {
      this.$refs.restByPhone.validateField('phone', (res) => {
        if (!res) {
          this.$refs.Captcha.handleOpenModal(this.form.phone)
        }
      })
    },
    /* 显示图形验证码 */
    handleShowCaptcha() {
      this.$refs.registerPhone.validateField('mobile', (res) => {
        if (!res) {
          this.$refs.Captcha.handleOpenModal(this.form.mobile)
        }
      })
    },
    handleSendMessage(code) {
      let url = '',
        formData = {}

      // if (!this.form.mobile)
      //   return this.$message.warning({
      //     content: (h) => <span style="margin-left: 9px; font-weight: 500;color: #262626;">手机号不能为空</span>,
      //     icon: (h) => <img src="/noPass.png" width="21" height="21" />,
      //   })
      url = '/sms/captcha'
      formData = {
        phone: this.form.mobile || this.form.phone,
        code,
        templateCode: this.type == 'register' ? 'TENCENT_REGISTER' : 'TENCENT_LOGIN',
      }
      // }
      postAction(url, formData).then((res) => {
        const { success, message } = res
        if (success) {
          this.$message.success({
            content: (h) => (
              <span style="margin-left: 9px; font-weight: 500;color: #262626;" > 验证码已经发送，请查看手机短信</ span >
            ),
            icon: (h) => <img src="/pass.png" width="21" height="21" />,
          })
          this.sendCodeSec = 60
          this.Timer = setInterval((_) => {
            this.sendCodeSec--
            if (this.sendCodeSec == 0) {
              clearInterval(this.Timer)
            }
          }, 1000)
        } else {
          this.$message.warning(message) // 失败
        }
      })
    },
    handleChangeTab(key) {
      this.type = key
      this.form = {}
      if (this.type == 'register') {
        this.form.roleType = 'BUSINESS'
      }
      this.activeKey = '1'
      this.Timer && clearInterval(this.Timer)
      this.sendCodeSec = 0
      this.loading = false
    },
    /* 登录 */
    handleLogin() {
      // 目前没接口模拟登录
      this.login().then((res) => {
        // this.$notification.success({
        //   message: '欢迎',
        //   description: `${timeFix()}，欢迎回来`
        // })
        // 获取用户信息
        this.handleLoadUserInfo(this.handleJumpPage)
      })
    },
    /* 手机号登录 */
    handleLoginByPhone() {
      // 我已阅读并同意
      this.$refs['loginByPhone'].validate((valid) => {
        if (valid) {
          // if (!this.form.isRead) return this.$message.warning({
          //   content: (h) => <span style="margin-left: 9px; font-weight: 500;color: #262626;">请勾选，我已阅读并同意用户协议和隐私协议</span>,
          //   icon: (h) => <img src="/noPass.png" width="21" height="21" />,
          // })
          this.loading = true
          const { mobile, captcha } = this.form
          postAction('/user/login/phone', {
            mobile,
            captcha,
          }).then((res) => {
            const { success, data, message } = res
            this.loading = false
            if (success) {
              const { tokenValue, tokenName, loginId } = data
              let Authorization = tokenValue
              localStorage.setItem('authInfo', JSON.stringify({ Authorization, userId: loginId }))
              // this.$message.success(message)
              this.handleLogin() // 进入系统
            } else {
              this.$message.warning(message)
            }
          }).catch(err => {
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
    /* 获取用户信息 */
    handleLoadUserInfo(callback) {
      let storageInfo = localStorage.getItem('authInfo')
      if (!storageInfo) {
        return this.$message.warning('用户ID不能为空')
      }
      const { userId } = JSON.parse(storageInfo)
      getAction('/user/info', { userId }).then((res) => {
        const { success, message, data } = res
        if (success) {
          let userInfo = JSON.stringify(data)
          localStorage.setItem('userInfo', userInfo)
          this.$store.commit('setUserInfo', data)
          callback()
        } else {
          this.$message.warning(message)
        }
      })
    },
    /* 账号密码登录 */
    handleLoginByUsername() {
      this.$refs['loginByUsername'].validate((valid) => {
        console.log(valid, this.form)
        if (valid) {
          // if (!this.form.isRead) return this.$message.warning({
          //   content: (h) => <span style="margin-left: 9px; font-weight: 500;color: #262626;">请勾选，我已阅读并同意用户协议和隐私协议</span>,
          //   icon: (h) => <img src="/noPass.png" width="21" height="21" />,
          // })
          this.loading = true
          const { password, username } = this.form
          postAction('/user/login/password', {
            password,
            userName: username,
          })
            .then((res) => {
              const { success, data, message } = res
              this.loading = false
              if (success) {
                // this.$message.success('登录成功')
                const { tokenValue, tokenName, loginId } = data
                let Authorization = tokenValue
                localStorage.setItem('authInfo', JSON.stringify({ Authorization, userId: loginId }))
                this.handleLogin() // 进入系统
              } else {
                this.$message.warning(message)
              }
            })
            .catch((err) => {
              // this.$message.warning('环境正在部署中')
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
    /* 跳转页面 */
    handleJumpPage() {
      const { query } = this.$route
      console.log(query)
      if (query.redirect) {
        const redirect = decodeURIComponent(query.redirect)
        setTimeout((_) => this.$router.push(redirect), 1500)
      } else {
        setTimeout((_) => this.$router.push('/'), 1500)
      }
    },
    /* 手机号注册 */
    handleRegisterByPhone() {
      this.$refs['registerPhone'].validate((valid) => {
        console.log(valid, this.form)
        if (valid) {
          if (!this.form.isRead) return this.$message.warning({
            content: (h) => <span style="margin-left: 9px; font-weight: 500;color: #262626;"> 请勾选，我已阅读并同意用户协议和隐私协议</ span >,
            icon: (h) => <img src="/noPass.png" width="21" height="21" />,
          })
          this.loading = true
          const { pwd, mobile, captcha, tradeIdentity, roleType, username } = this.form
          postAction('/user/register/phoneAndPassword', {
            captcha,
            mobile,
            pwd,
            tradeIdentity,
            roleType,
            userName: username
          }).then((res) => {
            const { code, message } = res
            this.loading = false
            if (code == '200') {
              this.$message.success('注册成功')
              // setTimeout((_) => this.handleChangeTab('login'), 1500)
              // 短信静默登录
              // postAction('/user/login/phone', {
              //   mobile,
              //   captcha,
              // }).then((res) => {
              //   const { success, data, message } = res
              //   this.loading = false
              //   if (success) {
              //     const { tokenValue, loginId } = data
              //     let Authorization = tokenValue
              //     localStorage.setItem('authInfo', JSON.stringify({ Authorization, userId: loginId }))
              //     this.$message.success(message)
              //     this.handleLogin() // 进入系统
              //   } else {
              //     this.$message.warning(message)
              //   }
              // }).catch(err => {
              //   this.loading = false
              // })
              // 手机号登录
              postAction('/user/login/password', {
                password: pwd,
                userName: username,
              })
                .then((res) => {
                  const { success, data, message } = res
                  this.loading = false
                  if (success) {
                    // this.$message.success('登录成功')
                    const { tokenValue, loginId } = data
                    let Authorization = tokenValue
                    localStorage.setItem('authInfo', JSON.stringify({ Authorization, userId: loginId }))
                    this.handleLogin() // 进入系统
                  } else {
                    this.$message.warning(message)
                  }
                })
            } else {
              this.$message.warning(message)
            }
          }).catch(e => {
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
    /* 邮箱注册 */
    handleRegisterByEmail() {
      this.$refs['registerEmail'].validate((valid) => {
        console.log(valid, this.form)
        if (valid) {
          this.loading = true
          postAction('/juno-users/register/email', {
            ...this.form,
          }).then((res) => {
            const { code, data, message } = res
            this.loading = false
            if (code == '200') {
              this.$message.success(message)
              setTimeout((_) => this.handleChangeTab('login'), 1500)
            } else {
              this.$message.warning(message)
            }
          })
        } else {
          return false
        }
      })
    },
    /* 切换tab */
    handleSwitchTab() {
      this.form = {}
      if (this.type == 'register') {
        this.form.roleType = 'BUSINESS'
      }
      this.loading = false
      this.Timer && clearInterval(this.Timer)
      this.sendCodeSec = 0
      this.$refs.loginByPhone && this.$refs.loginByPhone.clearValidate()
      this.$refs.loginByUsername && this.$refs.loginByUsername.clearValidate()
      this.$refs.registerPhone && this.$refs.registerPhone.clearValidate()
      this.$refs.registerPassword && this.$refs.registerPassword.clearValidate()
    },
    /* 手机号重置密码 */

    handleResetPasswordByPhone() {
      this.$refs['restByPhone'].validate((valid) => {
        if (valid) {
          // this.loading = true
          postAction('/user/pc/user/PassForgetUpdate', {
            ...this.form,
          }).then((res) => {
            const { code, message } = res
            this.loading = false
            if (code == '200') {
              this.$message.success('密码修改成功')
              setTimeout((_) => this.handleChangeTab('login'), 1500)
            } else {
              this.$message.warning(message)
            }
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

.login-content {
  width: 100%;
  margin: 0;
  height: 100vh;
  display: flex;
  background: #f6f6f6;
  background-image: url('~@/assets/newHomePage/banner-1.png');
  background-size: 100% 65%;
  background-position: 0 60px;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;

  .login-content-left {
    width: 70%;
    height: 100%;
    background-image: url('~@/assets/login/bg3.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;

    .logo {
      position: absolute;
      top: 30px;
      left: 30px;
      height: 32px;
    }

    .login-content-info {
      position: absolute;
      left: 28%;
      top: 33%;

      // transform: translateX(-50%);
      .p1 {
        font-weight: 600;
        font-size: 32px;
        color: white;
      }

      .p2 {
        font-weight: 600;
        font-size: 32px;
        color: white;
      }

      .p3 {
        font-size: 16px;
        color: white;
        margin-top: 30px;
      }
    }
  }

  .login-content-right {
    width: 30%;
    position: relative;
    height: auto;

    .login-box {
      // margin: 0 auto;
      width: 446px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      background: #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -40%);
      // background: #fff;
      padding: 35px 48px;
      border-radius: 6px;

      &-content {
        padding: 10px 0 0;
      }
    }

    .loginBtn {
      text-align: right;
      // margin: 4px 0;
    }

    .easy-btns-txt {
      text-align: center;
      margin: 30px 0 15px 0;
      color: #999999;
      font-size: 14px;
    }

    .easy-btns {
      // padding: 25px 30px 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &-item {
        cursor: pointer;

        &:first-child {
          margin-right: 30px;
        }

        img {
          width: 40px;
          margin-right: 6px;
        }
      }
    }

    .qrBox {
      .flexLayout(@justifyContent: center; );
    }

    .qrCode {
      width: 190px;
    }

    .resetPassword {

      ::v-deep .ant-tabs-bar {
        display: none;
      }
    }

    .logo {
      color: #fff;
      font-size: 30px;
      position: absolute;
      left: 30px;
      top: 30px;
    }

    ::v-deep .ant-tabs-nav-scroll {
      display: flex;
      justify-content: flex-start;
    }

    ::v-deep .ant-form-item {
      margin-bottom: 0;
    }

    // ::v-deep .ant-tabs-nav {
    //   font-size: 28px;
    //   font-weight: 600;
    //   color: #999 !important;

    //   .ant-tabs-tab:not(.ant-tabs-tab-active) {
    //     color: #999 !important;
    //   }
    // }

    // ::v-deep .ant-tabs-bar {
    //   border: none;
    // }

    // ::v-deep .ant-tabs-ink-bar {
    //   background-color: transparent;
    // }

    // ::v-deep .ant-tabs-nav .ant-tabs-tab:hover {
    //   font-size: 28px;
    //   font-weight: 600;
    //   color: #333;
    // }

    // ::v-deep .ant-tabs-nav .ant-tabs-tab-active {
    //   font-size: 28px;
    //   font-weight: 600;
    //   color: #333;
    // }

    ::v-deep .ant-input:focus {
      box-shadow: none;
    }

    ::v-deep .ant-input {
      width: 100%;
      height: 40px;
      background: #ffffff;
      border: 1px solid #dcdcdc !important;
      border-radius: 4px;
      padding-left: 15px;
      box-sizing: border-box;
      margin-block-start: 20px;
      -webkit-margin-before: 0px;
      margin-block-start: 0px;
    }

    .inp-box {
      width: 100%;
      position: relative;
      height: 40px;

      // margin-top: 20px;
      .inp-txt {
        position: absolute;
        right: 20px;
        top: 50%;
        z-index: 99;
        // bottom: 50%;
        transform: translateY(-50%);
        color: #ff6e2d;
      }
    }

    .inp-right-txt {
      text-align: left;
      // margin-top: 10px;
      margin-bottom: 20px;
      color: #ff6e2d;

      span {
        cursor: pointer;
      }
    }

    .login-btn {
      height: 40px !important;
      background: #ff6e2d !important;
      border-radius: 4px !important;
      border: 1px solid #ff6e2d !important;
    }
  }

  .inp-txt {
    cursor: pointer;
  }

  // .flexLayout(@justifyContent: flex-end;);
  ::v-deep .ant-radio-wrapper {
    margin-right: 0;
  }
}

::v-deep .ant-form-item-required {
  color: #000;
}

::v-deep .ant-radio-group span {
  color: #262626;
}

::v-deep .ant-checkbox-wrapper span {
  color: #999;

  a {
    color: #006490;
  }
}

.register-tabs {
  overflow: visible !important;

  ::v-deep .ant-tabs-bar {
    display: none;
  }

  .tradeIdentityItem {
    ::v-deep .ant-form-item-control {
      width: 245px;
    }
  }

  ::v-deep .has-error .ant-input,
  .has-error .ant-input:hover {
    border-color: #ee4261 !important;
    background-color: #fff;
  }
}

.mb-16 {
  margin-bottom: 16px !important;
}

::v-deep .ant-form-explain {
  margin: 8px 0 0;
  color: #ee4261 !important;
}

::v-deep .ant-tabs-tab {
  margin: 0;
}

.loginTab {
  ::v-deep .ant-tabs-tab {
    padding-left: 12px;
  }
}

.center-logo {
  width: 200px;
  height: 60px;
  margin-left: 55px;
  margin-bottom: 15px;
}

::v-deep .ant-tabs-nav {
  left: 50%;
  transform: translateX(-50%);
}

::v-deep .ant-tabs-tab {
  padding: 12px 0 !important;
  margin-right: 60px;
  font-weight: 600;

  &:not(.ant-tabs-tab-active) {
    color: #999999;
  }
}

a.link {
  color: #999;

  &:hover {
    color: #006490 !important;
  }
}

.police {
  text-align: center;
  color: #999999;
}

::v-deep .ant-tabs-bar {
  border-color: transparent;
}

.login-header {
  width: 100%;
  height: 60px;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding-left: 15px;

  .logo {
    width: auto;
    height: 40px;
  }
}

::v-deep .ant-form-explain {
  text-align: right;
}

.need-desc {
  .desc {
    color: #333;
    position: relative;

    &::after {
      display: inline-block;
      content: '';
      width: 1px;
      height: 25px;
      background: #dcdcdc;
      position: absolute;
      right: -8px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  ::v-deep .ant-input {
    padding-left: 80px !important;
  }
}

.block-line {
  width: 1px;
  height: 25px;
  background: #dcdcdc;
  position: absolute;
  right: 102px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 23;
}

.memo-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
}

.compact-input {
  width: 100%;
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  padding-left: 80px;
  position: relative;
  box-sizing: border-box;

  .desc {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  ::v-deep .ant-select {
    flex: 1 0 0;
  }

  ::v-deep .ant-select-selection {
    height: 40px;
    border: none;
  }

  ::v-deep .ant-select-selection__rendered {
    height: 40px;
    line-height: 40px;
  }
}

.inline-text {
  width: 100%;
  display: inline-block;
}
</style>
